@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body{
    overflow-x: hidden;
}
.navy{
    color: #171C2b
}

.pearl{
    color: #EDEDED
}

.pink{
    color: #F7C3DC
}

.orange{
    color: #E88863
}

.yellow{
    color: #EEBF45
}

.red{
    color: #DD4C45
}

.bgcolor-navy{
    background-color: #171C2b;
}

.bgcolor-yellow{
    background-color: #EEBF45;
}

.bgcolor-red{
    background-color:#DD4C45;
}

.bgcolor-pink{
    background-color:#F7C3DC;
}

.bgcolor-orange{
    background-color:#E88863;
}

.bgcolor-pearl{
    background-color: #EDEDED
}

.blur-background::before {
    content: '';
    position: absolute;
    left: 20%;
    top: 60%;
    transform: translate(-50%, -50%);
    width: 100px; 
    height: 100px; 
    background-color: #EEBF45;
    opacity: 0.4;
    border-radius: 50%;
    filter: blur(50px);
    z-index: -1;
}

.boxvalue1 {
    border-radius: 10px;
    /*width: 450px;*/
    border: solid 1px;
    border-color: #EEBF45;
    background-color: rgba(238, 191, 69, 0.05);
    padding: 10px;
    /*order: 1;*/
    /*background-color: #EEBF45;
    filter: blur(10px);*/
}

.boxvalue2 {
    border-radius: 10px;
    /*width: 450px;*/
    border: solid 1px;
    border-color:#F7C3DC;
    background-color: rgba(247, 195, 220, 0.05);
    padding: 10px;
    /*order: 2;*/
    /*background-color: #F7C3DC;
    filter: blur(10px);*/
}

.boxvalue3 {
    border-radius: 10px;
    /*width: 450px;*/
    border: solid 1px;
    border-color: #E88863;
    background-color: rgba(232, 136, 99, 0.05); 
    padding: 10px;
    /*order: 3;*/
}

.boxvalue4 {
    border-radius: 10px;
    /*width: 450px;*/
    border: solid 1px;
    border-color: #DD4C45;
    background-color: rgba(221, 76, 69, 0.05);
    padding: 10px;
    /*order: 4;*/
    /*background-color: #DD4C45;
    filter: blur(10px);*/
}

.boxhero-white {
    border-radius: 10px;
    border: solid 1px;
    border-color: #EDEDED;
    background-color: rgba(237, 237, 237, 0.05);
    padding: 10px;
}


.team1{
    background-color: #E88863;
    order: 1;
    color: #EDEDED;
}

.team2{
    background-color:#F7C3DC;
    order: 2;
    color: black;
}

.team3{
    background-color:#EEBF45;
    order: 3;
    color: black;
}

.team4{
    background-color: #DD4C45;
    order: 4;
    color: #EDEDED;
}

.team5{
    background-color: #F7C3DC;
    order: 5;
    color: black;
}

.metallic-effect {
    background: linear-gradient(145deg, #f7f7f7, #EDEDED, #d4d4d4, #bababa);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.offices-container {
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto
  }
  
.track {
    display: flex;
    flex-direction: row;
    width: 2000px;
    animation: scroll-left 20s linear infinite; 
}
.track2 {
    display: flex;
    flex-direction: row;
    width: 1500px;
    animation: scroll-right 20s linear infinite;
}
  
.address {
    margin: 0 20px;
    white-space: nowrap;
    border-radius: 100px;
    padding: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.paused {
    animation-play-state: paused;
}
  
@keyframes scroll-left {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}

@keyframes scroll-right {
    to {
        transform: translateX(0);
    }
    from {
        transform: translateX(-100%);
    }
}
  
@media (max-width: 460px) {
    .address {
        margin: 0 5px;
        white-space: nowrap;
        border-radius: 100px;
        padding: 10px;
        margin-top: 5px;
        margin-bottom: 5px;
        font-size: x-small;
    }

    .track {
        width: 600px;
        animation: scroll-left 30s linear infinite; 
    }
    .track2 {
        width: 600px;
        animation: scroll-right 30s linear infinite; 
    }
}

@media (max-width: 640px) {
    .address {
        margin: 0 5px;
        white-space: nowrap;
        border-radius: 100px;
        padding: 10px;
        margin-top: 5px;
        margin-bottom: 5px;
        font-size: x-small;
    }

    .track {
        width: 800px;
        animation: scroll-left 40s linear infinite; 
    }
    .track2 {
        width: 800px;
        animation: scroll-right 40s linear infinite; 
    }
}

@media (max-width: 1024px) {
    .address {
        margin: 0 5px;
        white-space: nowrap;
        border-radius: 100px;
        padding: 10px;
        margin-top: 5px;
        margin-bottom: 5px;
        font-size: x-small;
    }

    .track {
        width: 1200px;
        animation: scroll-left 40s linear infinite; 
    }
    .track2 {
        width: 1200px;
        animation: scroll-right 40s linear infinite; 
    }
}


@media (max-width: 768px) {
    .background-container {
        background-repeat: repeat; 
        background-size: contain;
    }
}


@media (min-width: 769px) {
    .background-container {
        background-repeat: no-repeat;
        background-position: center; 
        background-size: contain; 
    }
}

  